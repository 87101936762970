import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Static.module.scss';

const StaticSectionHeader = ({
  priority,
  children,
  centered,
  centeredMobile,
  padded,
}) => {
  const Tag = `h${priority}`;
  return (
    <Tag
      className={classNames(styles.header, {
        [styles.header_centered]: centered,
        [styles.header_centeredMobile]: centeredMobile,
        [styles.header_padded]: padded,
      })}
    >
      {children}
    </Tag>
  );
};

StaticSectionHeader.propTypes = {
  centered: PropTypes.bool,
  centeredMobile: PropTypes.bool,
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
  priority: PropTypes.number.isRequired,
};

StaticSectionHeader.defaultProps = {
  centered: false,
  centeredMobile: false,
  padded: false,
};

export default StaticSectionHeader;
